import { createSelector } from '@ngrx/store';
import { CourseViewModel } from '../../models/course.model';
import { SeenVideosMap } from '../../models/seen-guide.model';
import { ProgressVideosMap, VideoGuide, VideoGuideViewModel } from '../../models/video-guide.model';
import {
    mapExtendedCourseSectionsMetadata,
    selectAllExtendedCoursesWithProgress
} from './courses.selectors';
import { selectProgressVideosMap, selectSeenVideosMap } from './meta.selectors';
import { selectRedeemedItemIds } from './referral.selectors';
import { selectCustomRouteParamsFragment } from './route.selectors';
import { selectIsPremium } from './user.selectors';

export function mapVideoGuidesMetadata(
    videoGuides: VideoGuide[],
    seenVideosMap: SeenVideosMap,
    progressVideosMap: ProgressVideosMap,
    isPremium: boolean,
    redeemedItemIds: string[]
): VideoGuideViewModel[] {
    const today = new Date();
    const considerNewThreshold = today.setDate(today.getDate() - 3);

    return videoGuides
        .map(vg => {
            const giveawayRedemeed =
                redeemedItemIds.includes(vg.course?._id || ((vg.course as unknown) as string)) ||
                redeemedItemIds.includes(vg._id);
            return {
                ...vg,
                metadata: {
                    progress: (progressVideosMap[vg.videoId] / vg.duration) * 100 || 0,
                    seen: seenVideosMap[vg.videoId] ? true : false,
                    fullLengthAvailable: isPremium || vg.isFree || giveawayRedemeed,
                    newRelease: new Date(vg.releasedOn) > new Date(considerNewThreshold)
                },
                giveawayMetadata: {
                    isRedeemed: giveawayRedemeed
                },
                userMetadata: {
                    isPremium
                }
            };
        })
        .sort((a, b) => a.orderIndex - b.orderIndex) as VideoGuideViewModel[];
}

export const selectCurrentCourse = createSelector(
    selectAllExtendedCoursesWithProgress,
    selectCustomRouteParamsFragment,
    selectSeenVideosMap,
    selectProgressVideosMap,
    selectIsPremium,
    selectRedeemedItemIds,
    (
        courses: CourseViewModel[],
        [courseId],
        seenVideosMap: SeenVideosMap,
        progressVideosMap: ProgressVideosMap,
        isPremium,
        redeemedItemIds
    ) => {
        let crs = courses.find(c => c._id === courseId) || null;
        let sections = crs
            ? crs.sections
                  .map(sctn => ({
                      ...sctn,
                      videoGuides: mapVideoGuidesMetadata(
                          sctn.videoGuides,
                          seenVideosMap,
                          progressVideosMap,
                          isPremium,
                          redeemedItemIds
                      )
                  }))
                  .sort((a, b) => a.index - b.index)
            : [];

        crs = crs
            ? {
                  ...crs,
                  sections: mapExtendedCourseSectionsMetadata(sections)
              }
            : null;

        return crs;
    }
);

export const selectCurrentVideoGuide = createSelector(
    selectCurrentCourse,
    selectCustomRouteParamsFragment,
    (course: CourseViewModel, [courseId, videoGuideId]): VideoGuideViewModel | null => {
        if (course && course.sections && videoGuideId) {
            for (let i = 0; i < course.sections.length; i++) {
                const sctn = course.sections[i];

                for (let j = 0; j < sctn.videoGuides.length; j++) {
                    if (sctn.videoGuides[j]._id === videoGuideId) {
                        return sctn.videoGuides[j];
                    }
                }
            }
        }

        return null;
    }
);

export const selectCurrentCourseVideoGuides = createSelector(
    selectCurrentCourse,
    (course: CourseViewModel): VideoGuideViewModel[] | [] => {
        let videoGuides: VideoGuideViewModel[] = [];

        if (course && course.sections) {
            videoGuides = course.sections.reduce((acc, sctn) => {
                Array.prototype.push.apply(acc, sctn.videoGuides);

                return acc;
            }, []);
        }

        return videoGuides;
    }
);

